// Login.js
import React, { useState } from 'react';
import './Login.css'; // Import the CSS file

const Login = ({ handleLogin, handleSignup, setEmail, setPassword }) => {
  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Login</h2>
        <form>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />

          <label htmlFor="password">Password:</label>
          <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} />

          <button type="button" onClick={handleLogin}>
            Login
          </button>
          <button type="button" onClick={handleSignup}>
            Handle Signup
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
