// Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <div className="footer">
      <p>&copy; 2023 Your App. All rights reserved.</p>
    </div>
  );
};

export default Footer;
