import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './ListContainer.css';
import './DetailsContainer.css';
import './ListAndDisplayContainer.css'
import './style.css'

import MainPage from './MainPage';
import Login from './Login';
import Lists from './Lists';
import Details from './Details';

const supabase = createClient('https://pkotesbshotdxhkfiqmz.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBrb3Rlc2JzaG90ZHhoa2ZpcW16Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDUzNDA1MzcsImV4cCI6MjAyMDkxNjUzN30.k04RYdI7FrIOqcIRzYi1_MSjckaiI0jBmDzwj0ewabA');

function App() {
  const [user, setUser] = useState(null);
  const [lists, setLists] = useState([]); // State to store initial list
  const [clickedItem, setClickedItem] = useState(null); // Store the clicked item
  const [results, setResults] = useState([]); // State to store details
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [currentFlow, setCurrentFlow] = useState('main'); // 'main', 'login', 'lists', 'details'

  const handleGoBack = () => {
    setClickedItem(null);
    setResults([]);
    setCurrentFlow('lists');
  };

  const fetchLists = async () => {
    try {
      const { data, error } = await supabase.from('slists').select('*');
      console.log('NNJ Supabase response:', data, error);
      if (error) {
        throw new Error(`Supabase error: ${error.message}`);
      }
      console.log('NNJ list: ', data)
      setLists(data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // Fetch the initial list of strings on component load
  useEffect(() => {
    const checkUser = async () => {
        //await fetchLists();
        setCurrentFlow('main');
        const user = await supabase.auth.getUser();
        console.log('nnj check ', user)
        setUser(user.data.user ?? null);
        
        if (user.data.user) {
          await fetchLists();
          setCurrentFlow('lists');
        }
      };
    checkUser();
  }, []);

  const handleToggleFlow = (flow) => {
    setCurrentFlow(flow);
  };

  const handleLogin = async () => {
    const { user, error } = await supabase.auth.signInWithPassword({
      email: email, // Replace with the user's email
      password: password, // Replace with the user's password
    });
    console.log('NNJJ1: ', user)
    if (error) {
      console.error('Error logging in:', error.message);
    } else {
      const { data: { user } } = await supabase.auth.getUser()
      setUser(user);
      await fetchLists();
      setCurrentFlow('lists');
      console.log('NNJJ2: ', user)
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    setCurrentFlow('main');
  };

  const handleSignup = async () => {
    try {
      const { user, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        console.error('Error signing up:', error.message);
      } else {
        setUser(user);
      }
    } catch (error) {
      console.error('Error signing up:', error.message);
    }
  };

  // Function to handle the click event on a list item
  const handleItemClick = async (item, item_name) => {
    setClickedItem(item_name);

    try {
        const { data, error } = await supabase
          .from('sentries') // Replace with your actual table name
          .select('*')
          .eq('list_id', item);

        if (error) {
          throw new Error(`Supabase error: ${error.message}`);
        }

        setResults(data || []);
        setCurrentFlow('details');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  return (
    <div className="container"> 
      {currentFlow === 'main' && (
        <MainPage handleToggleFlow={handleToggleFlow} />
      )}
      {currentFlow !== 'main' &&  user && (
        <div className="list-and-display-container">
            <img
                src="https://storage.googleapis.com/tess_pub/transparent.png"  // Replace with the URL of your image
                alt="Header Image"
                style={{ width: '200px', height: '200px'}} // Adjust the styles as needed
            />
          <p>Welcome, {user.email}!</p>
          <button onClick={handleLogout}>Logout</button>
          {currentFlow === 'lists' && (
            <Lists lists={lists} handleItemClick={handleItemClick} />
          )}
          {currentFlow === 'details' && (
            <Details clickedItem={clickedItem} results={results} handleGoBack={handleGoBack} />
          )}
        </div>
      )} 
       {currentFlow !== 'main' && !user && (
        <Login
          handleLogin={handleLogin}
          handleSignup={handleSignup}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      )}
    </div>
  );
}

export default App;

