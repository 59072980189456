// Lists.js
import React from 'react';

const Lists = ({ lists, handleItemClick }) => {
  return (
    <div className="list-container">
      <ul>
        {lists.map((item) => (
          <li key={item.list_name} onClick={() => handleItemClick(item.id, item.list_name)}>
            {item.list_name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Lists;
