// MainPage.js
import React from 'react';
import './MainPage.css'; // Import the CSS file
import Footer  from './Footer';

const MainPage = ({ handleToggleFlow }) => {
  return (
    <div>
      <div className="header">
      <img
        src="https://storage.googleapis.com/tess_pub/transparent.png"  // Replace with the URL of your image
        alt="logo"
        className="logo"
      />
        <div className="login-signup">
          <button onClick={() => handleToggleFlow('login')}>Login</button>
        </div>
      </div>
      <div className="content">
        <div className="text-explanation">
          <h1>Personal lists made simple</h1>
          <p>Tired of complex list and todo apps? </p>
          <p> Try TinyLists build right into your messaging app.
            Coming soon for Whatsapp, SMS, Messenger.</p>
        </div>
        <div className="image-container">
          <img src="https://storage.googleapis.com/tess_pub/ezgif-7-6bc5612a9f.gif" alt="App Image" className="main-image" />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default MainPage;
