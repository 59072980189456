import React from 'react';

const Details = ({ clickedItem, results, handleGoBack }) => {
  return (
<div className="details-container">
<h2>Your {clickedItem} list</h2>
<button onClick={handleGoBack}>Go Back</button>
<ul>
  {results.map((detailItem) => (
    <li key={detailItem.entry_text}>{/* Replace detailItem with the correct key */ }
      {detailItem.entry_text} 
    </li>
  ))}
</ul>
</div>
  );
};

export default Details;